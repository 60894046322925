export default [
  {
    index: 0,
    key: "pais",
    labelKey: "country",
    color: "#fdbf6f",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/pais.zip'
  },
  {
    index: 1,
    key: "bioma",
    labelKey: "biome",
    color: "#222222",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:biome"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/bioma.zip'
  },
  {
    index: 2,
    key: "estado",
    labelKey: "state",
    color: "#eb2f06",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:state"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGVSB, adaptado por Provita' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/nivel_politico_1.zip'
  },
  {
    index: 3,
    key: "municipio",
    labelKey: "municipality",
    color: "#947438",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:municipality"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IGVSB, adaptado por Provita' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/nivel_politico_2.zip'
  },
  {
    index: 4,
    key: "cuenca_hidrográfica",
    labelKey: "watershed",
    color: "#2980B9",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:watershed"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Minec, 2014 y actualizado por Provita 2023' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/cuencas.zip'
  },
  {
    index: 5,
    key: "parque_nacional",
    labelKey: "national_park",
    color: "#55f8f8",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:national_park"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IVIC, 2014; POA, 2015 y Provita, 2023: Rodríguez, J.P., Zambrano-Martínez, S., Lazo, R., Oliveira-Miranda, M.A. (eds.), 2014, Representación Digital de las Áreas Naturales Protegidas de Venezuela, IVIC y Total Venezuela S.A.; Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE).' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/parque_nacional.zip'
  },
  {
    index: 6,
    key: "monumento_natural",
    labelKey: "natural_monument",
    color: "#fc795b",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:natural_monument"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'POA, 2015 y Provita, 2023: Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE).' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/monumento_natural.zip'
  },
  {
    index: 7,
    key: "reserva_fauna_silvestre",
    labelKey: "wildlife_reserve",
    color: "#6a3d9a",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:wildlife_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'POA, 2015 y Provita, 2023: Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE).' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/reserva_fauna_silvestre.zip'
  },
  {
    index: 8,
    key: "refugio_fauna_silvestre",
    labelKey: "wildlife_refuge",
    color: "#730e21",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:wildlife_refuge"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'POA, 2015 y Provita, 2023: Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE).' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/refugio_fauna_silvestre.zip'
  },
  {
    index: 9,
    key: "santuario_fauna_silvestre",
    labelKey: "wildlife_sanctuary",
    color: "#7f6b83",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:wildlife_sanctuary"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita, 2023: Digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/santuario_fauna_silvestre.zip'
  },
  {
    index: 10,
    key: "reserva_biosfera",
    labelKey: "biosphere_reserve",
    color: "#DFFF00",
    props: {
      url: 'https://shared-geoserver.mapbiomas.org/geoserver/ows',
      transparent: true,
      format: 'image/png',
      layers: "mapbiomas-venezuela:biosphere_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita, 2023: Digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/reserva_biosfera.zip'
  },
  {
    index: 11,
    key: "reserva_forestal",
    labelKey: "forest_reserve",
    color: "#27AE60",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:forest_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita, 2023: Digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/reserva_forestal.zip'
  },
  {
    index: 12,
    key: "ti_reconocido",
    labelKey: "officially_recognized_indigenous_territory",
    color: "#CCCCFF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:officially_recognized_indigenous_territory"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Wataniba y Provita, 2023: Ministerio del Poder Popular para la Salud, Salud Indígena en Venezuela (mapa), 2007; y Ministerio del Poder Popular para el Ambiente, Tierras Indígenas (mapa), 2014.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/ti_reconocido.zip'
  },
  {
    index: 13,
    key: "ti_en_proceso",
    labelKey: "indigenous_territory_without_official_recognition",
    color: "#FF00FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:indigenous_territory_without_official_recognition"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Wataniba y Provita, 2023: Territorios autodemarcados: Trabajo de Wataniba con Organizaciones indígenas (2014, 2022, 2023). Territorios tradicionales: Ministerio del Poder Popular para la Salud (mapa), 2007.' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/ti_en_proceso.zip'
  },
  {
    index: 14,
    key: "ramsar",
    labelKey: "ramsar_sites",
    color: "#008080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:ramsar_sites"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'POA, 2015 y Provita, 2023: Provita, 2023, digitalizado con base en la revisión de los decretos de creación de las gacetas oficiales; Planificación y Ordenación Ambiental (POA), 2015, Base de datos de las Área Bajo Régimen de Administración Especial (ABRAE).' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/ramsar.zip'
  },
  {
    index: 15,
    key: "ecoregion",
    labelKey: "ecoregion",
    color: "#99d8c9",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:ecoregion"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'RESOLVE Ecoregions dataset 2017' },
      { labelKey: 'year', label: 'Ano', value: '2017' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/ecoregion.zip'
  },
  {
    index: 16,
    key: "regiones_fisiograficas",
    labelKey: "physiographic_regions",
    color: "#fa9fb5",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-venezuela:physiographic_regions"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Provita 2023' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/venezuela/collection_1/download/layers/regiones_fisiograficas.zip'
  }
];
